
import {
    Grid,
    Box,
    Typography
  } from '@mui/material';

import moment from 'moment';
import { Icmd } from 'interfaces/common';
type ICardCommande = {
  cmd: any;
  handleClick : (id: number) => void;
}
const CardCommande = ({cmd, handleClick}:ICardCommande) => {

  

  return (
    <Grid item xs={12} sx={{height: "fit-content", cursor:"pointer"}} onClick={() => {
      handleClick(cmd.id)
    }}>
        <Box sx={{ background: "#fff", borderRadius: "13px", padding: "19px 16px", display:"flex", flexDirection:"column",gap:"5px"}}>
          <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
            <Typography sx={{ color:"#757575", fontSize:"inherit"}}>Client:</Typography>
            <Typography sx={{ fontSize:"inherit"}}>{cmd.Client.raisonSocial}</Typography>
          </Box>
          <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
            <Typography sx={{ color:"#757575", fontSize:"inherit"}}>Adresse:</Typography>
            <Typography sx={{ fontSize:"inherit"}}>{cmd.Client.adresse}</Typography>
          </Box>
          <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
            <Typography sx={{ color:"#757575", fontSize:"inherit"}}>{`Sous-total(${cmd.details_mouvement_stocks?.length}):`}</Typography>
            <Typography sx={{ fontSize:"inherit"}}>{cmd?.total} DZD</Typography>
          </Box>
          <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
            <Typography color="primary" sx={{  fontSize:"inherit", fontWeight:"bold"}}>#CMD{cmd.id}</Typography>
            <Typography sx={{ fontSize:"0.8rem", color:"#757575"}}>{`${moment(cmd.createdAt).format("DD/MM/YYYY HH:mm")}`}</Typography>
          </Box>
        </Box>
        
    </Grid>
  )
}

export default CardCommande
import React from "react";

import { Refine } from "@pankod/refine-core";
import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
  LightTheme,
  ReadyPage,
  ErrorComponent,
} from "@pankod/refine-mui";
import { Title, Sider, Layout, Header } from "components/layout";
import { Login } from "pages/login";
import dataProvider from "@pankod/refine-simple-rest";
import { authProvider } from "./authProvider";
import routerProvider from "@pankod/refine-react-router-v6";
import { AppProvider } from "contexts/app.context";
import axios from "axios";
import Profil from "pages/Profil";
import Commandes from "pages/Commandes";
import Commande from "pages/Commande";
import Stores from "pages/Stores";
import Historiques from "pages/Historiques";
import Historique from "pages/Historique";
import Store from "pages/Store";
function App() {
  
  const axiosInstance = axios.create();
  axiosInstance.defaults.withCredentials = true
const overridedLightTheme = {
    ...LightTheme,
    palette: {
        ...LightTheme.palette,
        primary: {
            main: "#92CE08",
            contrastText: "#fff"
        },
        secondary: {
            main: "#fff",
            secondary: "#000",
            contrastText: "#000"
        },
        third: {
          main: "#F9A400"
        },
        error: {
          main: "#C80000",
          lighter: "#FFE8E8"
        },
        text: {
          disabled: "#c1c1c1",
          primary: "#000",
          secondary: "#373737",
        }
    },
};
  return (
    <ThemeProvider theme={overridedLightTheme}>
      <AppProvider>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
      <RefineSnackbarProvider>
        <Refine
          /* @ts-ignore */
          dataProvider={dataProvider("/api",axiosInstance)}
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          resources={[
            {
              name: "stores",
              list: Stores,
              show: Store,
            },
            {
              name: "commandes",
              list: Commandes,
              show: Commande,
            },
            {
              name: "historique",
              list: Historiques,
              show: Historique,
            },
            {
              name:"profil",
              list: Profil,
            }
          ]}
          Title={Title}
          Sider={Sider}
          Layout={Layout}
          Header={Header}
          routerProvider={routerProvider}
          authProvider={authProvider}
          LoginPage={Login}
        />
      </RefineSnackbarProvider>
      </AppProvider>
    </ThemeProvider>
  );
}

export default App;

import React from "react";
import { LayoutProps } from "@pankod/refine-core";
import { Box } from "@pankod/refine-mui";

import { Sider as DefaultSider } from "../sider";
import { Header as DefaultHeader } from "../header";
import {Footer } from "../footer"
import { useCtxt } from "contexts/app.context";
import NavbarMobile from "components/common/NavbarMobile";
import { mobileWidth } from "components/constante";
import { useMediaQuery } from "@pankod/refine-mui";
export const Layout: React.FC<LayoutProps> = ({
  Sider,
  Header,
  OffLayoutArea,
  children,
}) => {
  const HeaderToRender = Header ?? DefaultHeader;
  const isMobile = useMediaQuery(mobileWidth)
  return (
    <Box display="flex" flexDirection="column" position="relative" className="container">
      <HeaderToRender />
      <Box
        sx={{
          display: "flex",
          flexDirection: "raw",
          flex: "auto",
          marginBottom: isMobile ? "10px":"0",
          overflowY: "auto",
          height: "100%",
        }}
        
      >
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            padding:"0 0 60px"
          }}
        >
          {children}
        </Box>
      </Box>
       <Box sx={{ position:"fixed", left:"0", bottom:"0", width:"100%"}}>
        <NavbarMobile />
      </Box> 
      
    </Box>
  );
};

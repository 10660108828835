import React, { useState,useContext, useEffect } from "react";
import {  useLogout,useGetIdentity } from "@pankod/refine-core";
import {
  AppBar,
  Stack,
  Toolbar,
  Typography,
  TextField,InputAdornment,Box,Link
} from "@pankod/refine-mui";
import SearchIcon from '@mui/icons-material/Search';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import {  useCtxt } from "contexts/app.context";
import { IUser} from "interfaces/common"
import { useNavigate } from "@pankod/refine-react-router-v6";
import useMediaQuery from '@mui/material/useMediaQuery';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { KeyboardArrowLeftOutlined, KeyboardArrowRightOutlined } from "@mui/icons-material";
import { mobileWidth } from "components/constante";
import logo from "../../../assets/logo.svg"
export const Header: React.FC = () => {
  const { mutate: logout } = useLogout();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(mobileWidth)
  const { data: userInfo } = useGetIdentity<IUser>();
  
  useEffect(() => {
    dispatch({ type: "setUser",payload: userInfo })
  }, [userInfo])
  
  const { ctxt: {user, produitPanier}, dispatch } = useCtxt();
  const [show,setShow] = useState(false)
  const [value, setValue] = React.useState('');
  const handleChange = (event :  React.ChangeEvent<HTMLInputElement>) => {
    setValue( event.target.value );
  };
  const handleRecherche = () => {
    dispatch({ type: "setRecherche",payload: value })
  }
  useEffect(() => {
    dispatch({ type: "setRecherche",payload: value })
  }, [value])
  const shouldRenderHeader = true; // since we are using the dark/light toggle; we don't need to check if user is logged in or not.
  const pagesMobile : any = {
    "/stores/show":"Store",
    "/commandes":"Commandes",
    "/commandes/show":"Details de la commande",
    "/historique":"Historique",
    "/profil": "Paramètres",
    "/historique/show":"Details de la commande"
  }

  return shouldRenderHeader ? (
    <>
    {pagesMobile[window.location.pathname.split("/", 3).join("/")] && <AppBar color="default" position="sticky" elevation={1} sx={{background: "transparent",}}>
      <Toolbar sx={{ padding:"0"}}>
         <Stack
          direction="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          padding="10px 20px"
          bgcolor="#F6F6F6"
          gap={isMobile ? "10px":"15px"}
        >
          {!isMobile &&<Box sx={{width: "95px"}}>
            <img src={logo} alt="logo img" />
          </Box>}
         {
              isMobile &&  <KeyboardArrowLeftOutlined sx={{ cursor:"pointer", fontSize:40}} onClick={() => {
                navigate("/stores")
              }} />
            }
        {
          isMobile && <Typography sx={{ textTransform:"capitalize", fontWeight:"bold"}}>{ pagesMobile[window.location.pathname.split("/", 3).join("/")]}</Typography>
        }
          <Stack
            direction="row"
            gap="16px"
            alignItems="center"
            justifyContent="center"
          >
            {isMobile &&<Box sx={{width: "40px"}}>
            <img src={logo} alt="logo img" />
          </Box>}
            
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>}
    </>
  ) : null;
};



import {
  Stack,Box
} from "@pankod/refine-mui";
import { useNavigate } from "@pankod/refine-react-router-v6";
import {  useCtxt } from "contexts/app.context";
import { PendingActionsOutlined, MapOutlined, ArchiveOutlined, Person } from '@mui/icons-material';
const NavbarMobile = () => {
  const { ctxt } = useCtxt();
    
    const navigate = useNavigate();
    const links = [
      {id: 1,name:"stores",label: "stores", icon: <MapOutlined sx={{ fontSize: 30,color: "inherit"}} />, navigate: "/stores"},
      {id: 4,name:"commandes",label: "commandes", icon: <ArchiveOutlined sx={{ fontSize: 30}} />, navigate: "/commandes"},
      {id: 2,name:"historique",label: "historique", icon: <PendingActionsOutlined sx={{ fontSize: 30}} />, navigate: "/historique"},
      {id: 5,name:"profil",label: "Profil", icon: <Person sx={{ fontSize: 30}} />, navigate: "/profil"},
    ]
  return (
     <Stack sx={{background:"#000",padding:"7px 14px", borderRadius:"10px 10px 0 0"}}>
      <Box sx={{display:"flex",gap:"10px", justifyContent:"space-between"}}>
          {links.map((link) => {return (
            <Box key={link.id} sx={{ display:"flex", gap:"10px", alignItems:"center", justifyContent: "space-between", padding:"13px 17px",borderRadius:"9px",color: window.location.pathname.split("/", 2).join("/") === link.navigate ? "#AFE259": "#fff",cursor:"pointer","&:hover": {
              color: "#c7e88f",
            },}} onClick={() => {navigate(`${link.navigate}`)}}>
              <Box sx={{display:"flex", gap:"10px", color: "inherit", position:"relative"}}>
              {link.icon}
              <Box sx={{position:"absolute", width:"90%", height:"3px", borderRadius:"50px",bottom:"-20%", left:"5%", background: window.location.pathname.split("/", 2).join("/") === link.navigate ? "#AFE259": "#000"}}></Box>
              </Box>
          </Box>
          )})}
          
      </Box>
      
  </Stack>
  )
}

export default NavbarMobile
import {
    Box,
    Typography,
  } from '@mui/material';
  import { LogoutOutlined, KeyboardArrowRightOutlined } from "@mui/icons-material";
  import {  useLogout } from "@pankod/refine-core";
import {  useCtxt } from "contexts/app.context";
import useMediaQuery from '@mui/material/useMediaQuery';
import { mobileWidth } from "components/constante";
const Profil = () => {  
    const isMobile = useMediaQuery(mobileWidth)  
    const { mutate: logout } = useLogout();
    const { ctxt: {user} } = useCtxt();
 return <Box>
    {isMobile && <Box sx={{ width:"100%", padding:"30px 20px"}}>
        <Box sx={{ display:"flex", width: "100%",flexDirection:"column",gap:"20px",marginBottom:"50px"}}>
            <Box sx={{ display:"flex", justifyContent:"space-between", gap:"10px"}}>
            <Typography sx={{color:"#6D6D6D"}}>Nom</Typography>
            <Typography sx={{ fontWeight:"bold"}} >{user?.last_name} {user?.first_name} </Typography>
            </Box>
            <Box sx={{ display:"flex", justifyContent:"space-between", gap:"10px"}}>
            <Typography sx={{color:"#6D6D6D"}}>Email</Typography>
            <Typography sx={{ fontWeight:"bold"}}>{user?.email}</Typography>
            </Box>
            <Box sx={{ display:"flex", justifyContent:"space-between", gap:"10px"}}>
            <Typography sx={{color:"#6D6D6D"}}>Numéro de téléphone</Typography>
            <Typography sx={{ fontWeight:"bold"}}>{user?.phone_number}</Typography>
            </Box>
            
        </Box>
        <Box sx={{width:"100%",display:"flex", flexDirection:"column",gap:"20px",padding:"12px 0px", margin:"10px 0", borderRadius:"10px"}}>
          <Box sx={{ display:"flex", gap:"10px", alignItems:"center", justifyContent: "space-between",background:"#FFE8E8",color:"#C80000",borderRadius:"9px", padding:"13px 17px",cursor:"pointer"}} onClick={() => {logout()}}>
            <Box sx={{display:"flex", gap:"10px", alignItems:"center"}}>
            <LogoutOutlined sx={{color:"#C80000"}} />
            <Typography sx={{fontSize: "12px", width:"max-content", textTransform: "uppercase"}}>Déconnexion</Typography>
            </Box>
            <KeyboardArrowRightOutlined />
            </Box>
          
      </Box>
    </Box>}
    
 </Box>
}

export default Profil

import {
    Grid,
    Box,
    Typography
  } from '@mui/material';

import moment from 'moment';
import cmdIcon from "../../assets/icons/cmd.svg"
import { Icmd } from 'interfaces/common';
type ICardCommande = {
  cmd: any;
  handleClick : (id: number) => void;
}
const CardStore = ({cmd, handleClick}:ICardCommande) => {

  

  return (
    <Grid item xs={12} sx={{height: "fit-content", cursor:"pointer"}} onClick={() => {
      handleClick(cmd.id)
    }}>
        <Box sx={{ width:"100%", background: "#fff", borderRadius: "13px", padding: "19px 16px", display:"flex",gap:"15px", alignItems:"center"}}>
          <Box>
            <img src={cmdIcon} alt="store" />
          </Box>
            <Box sx={{flex:"auto", display:"flex", flexDirection:"column", gap:"10px"}}>
                <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
                    <Typography sx={{  fontSize:"inherit",fontWeight:"bold"}}>#CMD{cmd?.id}:</Typography>
                    <Typography sx={{  fontSize:"inherit",fontWeight:"bold"}}>{`${moment(cmd?.createdAt).format("DD/MM/YYYY HH:mm")}`}</Typography>
                </Box>
                <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
                    <Typography sx={{ fontWeight:"bold", fontSize:"inherit"}}>{`Sous-total(${cmd?.details_mouvement_stocks?.length}):`}</Typography>
                    <Typography sx={{ fontSize:"inherit"}}>{cmd?.total} DZD</Typography>
                </Box>
            </Box>
        </Box>
        
    </Grid>
  )
}

export default CardStore
import { useState } from 'react'
import {
    Grid,
    Box,
    Typography, Skeleton
  } from '@mui/material';
import { Produit } from 'interfaces/common';
type ICardProduit = {
  produit: Produit;
}
const CardProduit = ({produit}:ICardProduit) => {
    const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <Grid item xs={12} sx={{height: "fit-content", cursor:"pointer"}}>
          <Box sx={{ background: "#fff", borderRadius: "13px", padding: "10px 15px", display:"flex",gap:"20px"}}>
            {produit.image && (
                <Box sx={{ maxWidth:"100px"}}>
                {!imageLoaded && <Skeleton variant="rectangular" width="100%" height={100} />}
                 <img
                    src={`https://freshfood.kbdev.co//uploads/${produit.image}`}
                    style={{ width: "90%", margin: "0 auto", display:"block", height:"100%", objectFit:"contain" }}
                    onLoad={() => setImageLoaded(true)}
                />
                </Box>
            )}
            <Box sx={{display:"flex", flexDirection:"column",gap:"3px"}}>
                <Typography>{produit.name}</Typography>
                <Typography>{produit.collisage}</Typography>
                <Typography sx={{ fontWeight:"bold"}}>{produit.quantity} x {produit.prix} DZD</Typography>
            </Box>
          </Box>
          
      </Grid>
  )
}

export default CardProduit
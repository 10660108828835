
import {
    Grid,
    Box,
    Typography
  } from '@mui/material';

import moment from 'moment';
import stateLivre from "../../assets/icons/stateLivre.svg"
import stateRetournee from "../../assets/icons/stateRetournee.svg"
import { Icmd } from 'interfaces/common';
type ICardCommande = {
  cmd: any;
  handleClick : (id: number) => void;
}
const CardHistorique = ({cmd, handleClick}:ICardCommande) => {

  

  return (
    <Grid item xs={12} sx={{height: "fit-content", cursor:"pointer"}} onClick={() => {
      handleClick(cmd.id)
    }}>
        <Box sx={{ background: cmd.state === "Livrée" ? "#F3FFD8": "#FFEBEB", borderRadius: "13px", padding: "19px 16px", display:"flex", flexDirection:"column",gap:"5px"}}>
          <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
            <Typography sx={{ color:"#757575", fontSize:"inherit"}}>Client:</Typography>
            <Typography sx={{ fontSize:"inherit"}}>{cmd.Client?.raisonSocial}</Typography>
          </Box>
          <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
            <Typography sx={{ color:"#757575", fontSize:"inherit"}}>{`Sous-total(${cmd.details_mouvement_stocks?.length}):`}</Typography>
            <Typography sx={{ fontSize:"inherit", fontWeight:"bold"}}>{cmd.order?.totalPrice} DZD</Typography>
          </Box>
          <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
            <Typography sx={{  fontSize:"inherit"}}>#CMD{cmd.id}</Typography>
            <Typography sx={{ fontSize:"0.8rem", color:"#757575"}}>{`${moment(cmd.createdAt).format("DD/MM/YYYY HH:mm")}`}</Typography>
          </Box>
          <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
            <Typography sx={{ color:"#757575", fontSize:"inherit", textTransform:"capitalize"}}>état:</Typography>
            {cmd.order.state === "Livrée" && <Box sx={{display:"flex",gap:"5px",alignItems:"center"}}>
                <img src={stateLivre} alt="state icon" />
                  <Typography sx={{color:"#92CE08", fontSize:"0.8rem"}}>{cmd.state}</Typography>
              </Box>}
              {cmd.order.state !=="Livrée" && <Box sx={{display:"flex",gap:"5px", alignItems:"center"}}>
                <img src={stateRetournee} alt="state icon" />
                  <Typography sx={{color:"#E50000", fontSize:"0.8rem", whiteSpace:"nowrap"}}>{cmd.order.state}</Typography>
              </Box>}
          </Box>
        </Box>
        
    </Grid>
  )
}

export default CardHistorique
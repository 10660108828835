
import {
    Box,
    Typography,
  } from '@mui/material';

import { useNavigate,useParams } from "@pankod/refine-react-router-v6";
import {  useOne, HttpError  } from "@pankod/refine-core";
import { IStore } from 'interfaces/common';
import Loader from 'components/common/Loader';
import useMediaQuery from '@mui/material/useMediaQuery';
import storeImg from "../assets/store.svg"
import { mobileWidth } from "components/constante";
import { LocalGroceryStoreOutlined } from '@mui/icons-material';

import CardStore from 'components/common/CardStore';

const Store = () => {
    const { id } = useParams();
    const isMobile = useMediaQuery(mobileWidth)  
    const navigate = useNavigate();
    
    const { data, isLoading, isError }= useOne<IStore, HttpError>({
        resource: "driver/orders",
        id: id as string,
    });
    
    
    
   
    /* @ts-ignore */
    const infos = data?.data ?? [];
    const handleClick = (id: number) => {
        navigate(`/commandes/show/${id}`)
    }
    
   
 
    if (isLoading) return <Loader />;
    if (isError) return <Typography>Error...</Typography>;
  return (
    <Box sx={{ display:"flex",flexDirection:"column", width: "100%", padding:"20px 25px 10px", maxHeight:"100%",overflowY:"hidden"}}>
        <Box sx={{width:"100%", display:"flex", justifyContent:"center",marginBottom:"10px"}}>
            <img src={storeImg} alt="store" />
          </Box>
        <Box sx={{ display:"flex", flexDirection:"column",gap:"5px",margin:"10px 0 20px"}}>
            <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
                    <Typography sx={{  fontSize:"inherit",fontWeight:"bold"}}>Gérant:</Typography>
                    {/* @ts-ignore */}
                    <Typography sx={{  fontSize:"inherit", color:"#757575"}}>{infos?.gerant}</Typography>
                </Box>
                <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
                    <Typography sx={{  fontSize:"inherit",fontWeight:"bold"}}>Numéro:</Typography>
                    {/* @ts-ignore */}
                    <Typography sx={{ fontSize:"inherit", color:"#757575"}}>{infos?.user?.phone_number}</Typography>
                </Box>
                <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
                    <Typography sx={{  fontSize:"inherit",fontWeight:"bold"}}>Adresse:</Typography>
                    {/* @ts-ignore */}
                    <Typography sx={{ fontSize:"inherit", color:"#757575"}}>{infos?.adresse}</Typography>
                </Box>
                <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
                    <Typography sx={{  fontSize:"inherit",fontWeight:"bold"}}>Email:</Typography>
                    {/* @ts-ignore */}
                    <Typography sx={{ fontSize:"inherit", color:"#757575"}}>{infos?.user?.email}</Typography>
                </Box>
            </Box>
    { <Box sx={{width: isMobile ? "100% ":"80%", position: "relative",  display: "flex", justifyContent: "space-between", margin: "10px 0", overflowY:"auto"}}>
    {/* @ts-ignore */}
    {infos?.mouvement_stocks?.length > 0 && ( 
    <>  
    <Box sx={{position: "relative", flex:"auto", gap:"20px", margin: "0px", background:"transparent",  display: "flex", flexDirection: "column"}}>
        {/* @ts-ignore */}
        {infos?.mouvement_stocks.map((cmd) => {return (
            <CardStore key={cmd.id} cmd={cmd} handleClick={handleClick} />
        ) })}
    </Box>
    
        </>
    )}
        {/* @ts-ignore */}
        {infos?.mouvement_stocks?.length===0 && (
          <Box sx={{width: "100%",height:"100%", position: "relative", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center",gap:"10px"}}>
          <LocalGroceryStoreOutlined sx={{color:"#CFCFCF",fontSize: isMobile ? 80 : 180}} />
          <Typography color="#CFCFCF" variant='h4' sx={{fontSize: isMobile ? "0.9rem":"1.3rem", textAlign:"center"}}>Ce store n'a aucune commande</Typography>
      </Box>
            )}
    </Box>}
    
    </Box>
  )
}

export default Store
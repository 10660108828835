import {
    Grid,
    Box,
    Typography,
    Button,
  } from '@mui/material';

import axios from 'axios';
import { useNavigate,useParams } from "@pankod/refine-react-router-v6";
import {  useOne  } from "@pankod/refine-core";
import { Produit } from 'interfaces/common';
import Loader from 'components/common/Loader';
import Swal from 'sweetalert2';
import moment from 'moment';
import {  PaymentOutlined } from '@mui/icons-material';
import CardProduit from 'components/common/CardProduit';

const Commande = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    
    const { data, isLoading, isError }= useOne({
        resource: "driver/order",
        id: id as string,
    });
    
    
   
    /* @ts-ignore */
    const cmd = data?.data ?? {};
    const handleRetourner = async () => {
        await axios.put("/api/driver/etat/"+cmd.orderId,{state: "Retournée"},{
            withCredentials: true,
          }).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    position: 'bottom-right',
                    icon: 'success',
                    text: ' "Votre commande a bien été retournée"',
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: 'small-swal'
                  });
                navigate("/historique")
              }
        })
        .catch((e) => {
            console.log(e)
            Swal.fire({
                position: 'bottom-right',
                icon: 'error',
                text: ' La commande n a pas été retournée',
                showConfirmButton: false,
                timer: 1500,
                customClass: 'small-swal'
              });
        })
    }
    const handleLivre = async () => {
        await axios.put("/api/driver/etat/"+cmd.orderId,{state: "Livrée"},{
            withCredentials: true,
          }).then((res) => {
            if (res.status === 200) {
                Swal.fire({
                    position: 'bottom-right',
                    icon: 'success',
                    text: ' "Votre commande a bien été livrée"',
                    showConfirmButton: false,
                    timer: 1500,
                    customClass: 'small-swal'
                  });
                navigate("/historique")
              }
        })
        .catch((e) => {
            console.log(e)
            Swal.fire({
                position: 'bottom-right',
                icon: 'error',
                text: ' La commande n a pas été livrée',
                showConfirmButton: false,
                timer: 1500,
                customClass: 'small-swal'
              });
        })
    }
   
 
    if (isLoading) return <Loader />;
    if (isError) return <Typography>Error...</Typography>;
  return (
    <Box sx={{ display:"flex", minHeight:"100%",  width: "100%"}}>
    {  <Box sx={{padding:"0 20px", width: "100%", position: "relative", minHeight: "100%", display: "flex",flexDirection: "column", justifyContent: "space-between", margin: "10px 0",gap:"30px"}}>
        <Box>
            <Box sx={{ display:"flex", flexDirection:"column",gap:"7px",margin:"10px 0 20px"}}>
            <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
                    <Typography sx={{  fontSize:"inherit"}}>#CMD{cmd.id}:</Typography>
                    <Typography sx={{  fontSize:"inherit"}}>Il y a {moment(moment().diff(moment(cmd?.createdAt))).format("d") !== "0" ? `${moment(moment().diff(moment(cmd?.createdAt))).format("d")} jours`: `${moment(moment().diff(moment(cmd?.createdAt))).format("h")} heures`}</Typography>
                </Box>
                <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
                    <Typography sx={{  fontSize:"inherit"}}>Client:</Typography>
                    <Typography sx={{ fontSize:"inherit", color:"#757575"}}>{cmd.Client?.raisonSocial}</Typography>
                </Box>
                <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
                    <Typography sx={{  fontSize:"inherit"}}>Numéro:</Typography>
                    <Typography sx={{ fontSize:"inherit", color:"#757575"}}>{cmd.Client?.user?.phone_number}</Typography>
                </Box>
                <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
                    <Typography sx={{  fontSize:"inherit"}}>Adresse:</Typography>
                    <Typography sx={{ fontSize:"inherit", color:"#757575"}}>{cmd.Client?.adresse}</Typography>
                </Box>
            </Box>
            <Grid rowSpacing={{xs: 1, sm: 1, md: 1 }} columnSpacing={{xs: 0, md:2}} sx={{position: "relative", flex:"auto", gap:"5px", margin: "0px", background:"transparent", display: "flex", flexDirection: "column"}}>
                {cmd.details_mouvement_stocks?.map((order : Produit) => {return (
                    <CardProduit key={order.id} produit={order} />
                    
                ) })}
            </Grid>
        </Box>
        <Box sx={{ flex:"auto", display: "flex", flexDirection:"column", justifyContent:"flex-end",gap:"20px"}}>
            <Box sx={{display: "flex", alignItems: "center", gap: "25px", justifyContent:"space-between", fontSize:"0.9rem"}}>
                <Box sx={{display: "flex", alignItems: "center", gap: "6px"}}>
                    <PaymentOutlined />
                    <Typography sx={{display:"flex", fontSize:"inherit"}}>SOUS-TOTAL (<Typography sx={{fontWeight:"bold", fontSize:"inherit"}}>{cmd.details_mouvement_stocks?.length}</Typography>)</Typography>
                </Box>
                <Typography sx={{fontWeight:"bold", fontSize:"inherit"}}>{`${cmd.total} DA`}</Typography>
            </Box>
            <Box sx={{ display:"flex",width:"100%", justifyContent:"space-between",gap:"10px"}}>
                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{
                    height: 20,
                    flex: "1",
                    borderRadius: "3px",
                    fontSize: "10px",
                    fontWeight: "500",
                    fontWweight: 600,
                    padding: "20px 46px"
                    }}
                    onClick={() => {
                    handleRetourner()
                    }}
                >
                    Retourner
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    sx={{
                    flex: "1",
                    height: 20,
                    borderRadius: "3px",
                    fontSize: "10px",
                    fontWeight: "500",
                    fontWweight: 600,
                    padding: "20px 46px"
                    }}
                    onClick={() => {
                    handleLivre()
                    }}
                >
                    Livré
                </Button>
            </Box>
        </Box>
    </Box>}
    
    </Box>
  )
}

export default Commande

import {
    Grid,
    Box,
    Typography,
  } from '@mui/material';

import { useParams } from "@pankod/refine-react-router-v6";
import {  useOne  } from "@pankod/refine-core";
import { Produit } from 'interfaces/common';
import Loader from 'components/common/Loader';
import moment from 'moment';
import { PaymentOutlined } from '@mui/icons-material';
import CardProduit from 'components/common/CardProduit';
import stateLivre from "../assets/icons/stateLivre.svg"
import stateRetournee from "../assets/icons/stateRetournee.svg"

const Historique = () => {
    const { id } = useParams();
    
    const { data, isLoading, isError }= useOne({
        resource: "driver/order",
        id: id as string,
    });
    
    
   
    /* @ts-ignore */
    const cmd = data?.data ?? {};
   
 
    if (isLoading) return <Loader />;
    if (isError) return <Typography>Error...</Typography>;
  return (
    <Box sx={{ display:"flex",  width: "100%",minHeight: "100%", background: cmd.state ==="Livrée" ? "#F3FFD8": "#FFEBEB",padding:"5px 0 20px"}}>
    {  <Box sx={{padding:"0 20px", width: "100%", position: "relative", minHeight: "100%", display: "flex",flexDirection: "column", justifyContent: "space-between", margin: "10px 0", gap:"30px"}}>
        <Box>
            <Box sx={{ display:"flex", flexDirection:"column",gap:"7px",margin:"10px 0 20px"}}>
            <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
                    <Typography sx={{ fontWeight:"bold", fontSize:"inherit"}}>#CMD{cmd.id}:</Typography>
                    <Typography sx={{  fontSize:"inherit"}}>Il y a {moment(moment().diff(moment(cmd?.createdAt))).format("d") !== "0" ? `${moment(moment().diff(moment(cmd?.createdAt))).format("d")} jours`: `${moment(moment().diff(moment(cmd?.createdAt))).format("h")} heures`}</Typography>
                </Box>
                <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
                    <Typography sx={{ fontWeight:"bold", fontSize:"inherit"}}>Client:</Typography>
                    <Typography sx={{ fontSize:"inherit", color:"#757575"}}>{cmd.Client.raisonSocial}</Typography>
                </Box>
                <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
                    <Typography sx={{ fontWeight:"bold", fontSize:"inherit"}}>Numéro:</Typography>
                    <Typography sx={{ fontSize:"inherit", color:"#757575"}}>{cmd.Client.user.phone_number}</Typography>
                </Box>
                <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
                    <Typography sx={{ fontWeight:"bold", fontSize:"inherit"}}>Adresse:</Typography>
                    <Typography sx={{ fontSize:"inherit", color:"#757575"}}>{cmd.Client.adresse}</Typography>
                </Box>
                <Box sx={{ width: "100%",fontSize:"0.9rem", display:"flex", justifyContent:"space-between"}}>
                    <Typography sx={{ fontWeight:"bold", fontSize:"inherit", textTransform:"capitalize"}}>état:</Typography>
                    {cmd.state === "Livrée" && <Box sx={{display:"flex",gap:"5px",alignItems:"center"}}>
                        <img src={stateLivre} alt="state icon" />
                        <Typography sx={{color:"#92CE08", fontSize:"0.8rem"}}>{cmd.state}</Typography>
                    </Box>}
                    {cmd.state !=="Livrée" && <Box sx={{display:"flex",gap:"5px", alignItems:"center"}}>
                        <img src={stateRetournee} alt="state icon" />
                        <Typography sx={{color:"#E50000", fontSize:"0.8rem", whiteSpace:"nowrap"}}>{cmd.state}</Typography>
                    </Box>}
                </Box>
            </Box>
            <Grid rowSpacing={{xs: 1, sm: 1, md: 1 }} columnSpacing={{xs: 0, md:2}} sx={{position: "relative", flex:"auto", gap:"5px", margin: "0px", background:"transparent", display: "flex", flexDirection: "column"}}>
                {cmd.details_mouvement_stocks.map((order : Produit) => {return (
                    <CardProduit key={order.id} produit={order} />
                    
                ) })}
            </Grid>
        </Box>
        <Box sx={{ flex:"auto", display: "flex", flexDirection:"column", justifyContent:"flex-end",gap:"20px"}}>
            <Box sx={{display: "flex", alignItems: "center", gap: "25px", justifyContent:"space-between", fontSize:"0.9rem"}}>
                <Box sx={{display: "flex", alignItems: "center", gap: "6px"}}>
                    <PaymentOutlined />
                    <Typography sx={{display:"flex", fontSize:"inherit"}}>SOUS-TOTAL (<Typography sx={{fontWeight:"bold", fontSize:"inherit"}}>{cmd.details_mouvement_stocks?.length}</Typography>)</Typography>
                </Box>
                <Typography sx={{fontWeight:"bold", fontSize:"inherit"}}>{`${cmd.total} DA`}</Typography>
            </Box>
        </Box>
    </Box>}
    
    </Box>
  )
}

export default Historique